import React from 'react';
import { Anchor } from 'antd';
import { useTranslation } from 'react-i18next';

const { Link } = Anchor;

function PrivacyMenu() {
  const { t } = useTranslation();

  const handleGetContainer = () => document.querySelector('#term-right');

  return (
    <Anchor
      style={{ width: 300, offsetTop: 64, background: 'unset', height: '100%' }}
      getContainer={handleGetContainer}
      showInkInFixed
      affix
    >
      <Link href="#1-general" title={t('privacy:1-general')} />
      <Link
        href="#2-acquisition-of-personal-information"
        title={t('privacy:2-acquisition-of-personal-information')}
      />
      <Link
        href="#3-use-of-personal-information"
        title={t('privacy:3-use-of-personal-information')}
      />
      <Link
        href="#4-safe-management-of-personal-information"
        title={t('privacy:4-safe-management-of-personal-information')}
      />
      <Link
        href="#5-consignment-of-personal-information"
        title={t('privacy:5-consignment-of-personal-information')}
      />
      <Link
        href="#6-provision-of-personal-information-to-a-third-party"
        title={t(
          'privacy:6-provision-of-personal-information-to-a-third-party'
        )}
      />
      <Link
        href="#7-disclosure-and-correction-of-personal-information"
        title={t('privacy:7-disclosure-and-correction-of-personal-information')}
      />
      <Link
        href="#8-changes-to-this-policy"
        title={t('privacy:8-changes-to-this-policy')}
      />
      <Link
        href="#9-governing-law-court-of-jurisdiction"
        title={t('privacy:9-governing-law-court-of-jurisdiction')}
      />
    </Anchor>
  );
}

export default React.memo(PrivacyMenu);
