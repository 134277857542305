import React from 'react';
import { Layout, Row, Col, Typography } from 'antd';
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next';
import SEO from 'components/SEO';
import Header from 'components/Landing/Header';
import PreviewCompatibleImage from 'components/PreviewCompatibleImage';
import { useStaticQuery, graphql } from 'gatsby';
import PrivacyMenu from './PrivacyMenu';

import './index.less';

const { Content } = Layout;
const { Title, Paragraph } = Typography;

export default function Term() {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      term: file(relativePath: { eq: "privacy.svg" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
        extension
        publicURL
      }
    }
  `);

  return (
    <Layout id="term" className="term-layout">
      <SEO title={t('privacy:privacy')} description={t('SEODescription')} />
      <Row type="flex" justify="center">
        <Col xs={24}>
          <Header key="header" style={{ backgroundColor: 'white' }} />
        </Col>
      </Row>
      <Content className="content-container">
        <Row style={{ flex: 1 }}>
          <Col flex="300px" id="term-left">
            <PrivacyMenu />
          </Col>
          <Col flex="1" id="term-right">
            <Paragraph style={{ textAlign: 'center' }}>
              <PreviewCompatibleImage
                imageInfo={data.term}
                style={{ height: '200px' }}
              />
              <Title style={{ marginTop: 30 }}>
                <Trans ns="privacy" i18nKey="privacy" />
              </Title>
            </Paragraph>
            <Paragraph>
              <Title level={2} id="1-general">
                <Trans ns="privacy" i18nKey="1-general" />
              </Title>
              <Trans
                ns="privacy"
                i18nKey="human-crest-co-ltd-our-company-in-providing-our-services-general-term-for-services-and-software-provided-by-our-company-we-will-keep-personal-information-such-as-users-by-complying-with-the-laws-and-regulations-regarding-personal-information-and-by-properly-handling-personal-information-we-will-strive-to-provide-our-customers-with-greater-reliability-and-security"
              />
            </Paragraph>
            <Paragraph>
              <Title level={2} id="2-acquisition-of-personal-information">
                <Trans
                  ns="privacy"
                  i18nKey="2-acquisition-of-personal-information"
                />
              </Title>
              <Trans
                ns="privacy"
                i18nKey="our-company-will-properly-obtain-personal-information-without-any-false-or-other-fraudulent-means"
              />
            </Paragraph>
            <Paragraph>
              <Title level={2} id="3-use-of-personal-information">
                <Trans ns="privacy" i18nKey="3-use-of-personal-information" />
              </Title>
              <Paragraph>
                <Trans
                  ns="privacy"
                  i18nKey="we-may-change-discontinue-or-discontinue-any-part-of-this-service-provided-at-any-time"
                />
              </Paragraph>
              <Paragraph>
                <Trans
                  ns="privacy"
                  i18nKey="our-company-will-use-personal-information-to-the-extent-necessary-to-achieve-the-following-purposes-of-use-when-using-personal-information-for-purposes-not-specified-below-with-the-consent-of-the-person-in-advance-i-will-do-it-with"
                />
              </Paragraph>
              <Paragraph>
                <ul>
                  <li>
                    <Trans ns="privacy" i18nKey="providing-our-services" />
                  </li>
                  <li>
                    <Trans
                      ns="privacy"
                      i18nKey="providing-information-and-contacting-us-about-our-services"
                    />
                  </li>
                  <li>
                    <Trans
                      ns="privacy"
                      i18nKey="maintenance-auditing-research-and-analysis-of-our-services"
                    />
                  </li>
                  <li>
                    <Trans
                      ns="privacy"
                      i18nKey="protect-the-property-and-rights-of-us-or-you-and-your-users"
                    />
                  </li>
                  <li>
                    <Trans ns="privacy" i18nKey="developing-our-new-service" />
                  </li>
                </ul>
              </Paragraph>
            </Paragraph>
            <Paragraph>
              <Title level={2} id="4-safe-management-of-personal-information">
                <Trans
                  ns="privacy"
                  i18nKey="4-safe-management-of-personal-information"
                />
              </Title>
              <Trans
                ns="privacy"
                i18nKey="our-company-will-take-necessary-and-appropriate-measures-to-prevent-the-leakage-loss-or-damage-of-the-personal-information-we-handle-and-to-manage-the-safety-of-other-personal-information"
              />
            </Paragraph>
            <Paragraph>
              <Title level={2} id="5-consignment-of-personal-information">
                <Trans
                  ns="privacy"
                  i18nKey="5-consignment-of-personal-information"
                />
              </Title>
              <Trans
                ns="privacy"
                i18nKey="when-we-entrust-all-or-part-of-the-handling-of-personal-information-to-a-third-party-it-is-necessary-and-appropriate-for-the-third-party-to-ensure-the-safety-management-of-the-personal-information-entrusted-with-the-handling-i-will-supervise"
              />
            </Paragraph>
            <Paragraph>
              <Title
                level={2}
                id="6-provision-of-personal-information-to-a-third-party"
              >
                <Trans
                  ns="privacy"
                  i18nKey="6-provision-of-personal-information-to-a-third-party"
                />
              </Title>
              <Trans
                ns="privacy"
                i18nKey="our-company-will-not-provide-personal-information-to-a-third-party-without-the-prior-consent-of-the-individual-unless-otherwise-provided-by-laws-such-as-the-personal-information-protection-law"
              />
            </Paragraph>
            <Paragraph>
              <Title
                level={2}
                id="7-disclosure-and-correction-of-personal-information"
              >
                <Trans
                  ns="privacy"
                  i18nKey="7-disclosure-and-correction-of-personal-information"
                />
              </Title>
              <Trans
                ns="privacy"
                i18nKey="our-company-will-promptly-disclose-the-personal-information-of-its-own-if-it-is-requested-by-the-individual-and-will-not-respond-to-the-disclosure-if-the-individual-cannot-be-confirmed-if-there-is-an-error-in-the-content-of-personal-information-and-there-is-a-request-for-correction-addition-or-deletion-from-the-individual-we-will-promptly-respond-to-these-requests-after-an-investigation-if-you-have-any-requests-or-inquiries-regarding-the-handling-of-personal-information-of-our-company-please-contact-us-below"
              />
              <Paragraph>
                <Trans
                  ns="privacy"
                  i18nKey="email-address-moppiumhumancrestcojp"
                >
                  <a href="mailto:moppium@humancrest.co.jp">
                    moppium@humancrest.co.jp
                  </a>
                </Trans>
              </Paragraph>
            </Paragraph>
            <Paragraph>
              <Title level={2} id="8-changes-to-this-policy">
                <Trans ns="privacy" i18nKey="8-changes-to-this-policy" />
              </Title>
              <Trans
                ns="privacy"
                i18nKey="the-contents-of-this-policy-are-subject-to-change-the-changed-policy-will-take-effect-from-the-time-it-is-posted-on-our-website-unless-otherwise-specified-by-our-company"
              />
            </Paragraph>
            <Paragraph>
              <Title level={2} id="9-governing-law-court-of-jurisdiction">
                <Trans
                  ns="privacy"
                  i18nKey="9-governing-law-court-of-jurisdiction"
                />
              </Title>
              <Trans
                ns="privacy"
                i18nKey="all-types-of-disputes-between-you-and-us-regarding-this-matter-shall-be-governed-by-the-laws-of-japan-and-the-tokyo-district-court-shall-be-the-exclusive-court-of-jurisdiction-of-the-first-instance"
              />
            </Paragraph>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}
